// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue';
import ExperienceView from '@/views/ExperienceView.vue';
import ProjectView from "@/views/ProjectView.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/experiences',
    name: 'Experiences',
    component: ExperienceView
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectView
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
