<script setup>
import { experiences } from '@/assets/data/experiences';

const mapTechToStyle = {
  stack: "bg-white",
  dataStructures: "bg-blue-700",
  designPatterns: "bg-yellow-700",
}
</script>

<template>
  <div 
    data-main-container class="flex flex-col gap-3 md:flex-row flex-wrap bg-[#FCF3D6] overflow-y-auto p-2 md:justify-center items-center min-h-screen font-mono">



    <div 
      v-for="experience in experiences" 
      :key="experience.id" 
      class="experience-card  md:w-[400px] bg-black text-[#EDDDD4]  p-2 py-3 rounded m-3 shadow-black shadow-2xl">
      
      <div class="flex flex-col h-full text-center">
        <a  v-html="experience.company" :href="experience.site"  target="_blank" class="text-xl font-semibold hover:text-green-400 transition-colors cursor-pointer"></a>

        <small class="" v-html="experience.role"/>
        <small class="italic" v-html="experience.duration"/>
        
        <!-- Loop through technologies -->
        <div class="flex flex-wrap mt-4">
          <small 
            v-for="(tech, index) in [
              ...(experience.tech.stack || []), 
              ...(experience.tech.dataStructures || []), 
              ...(experience.tech.designPatterns || [])
            ]" 
            :key="index" 
            class="p-1 rounded m-1 text-black font-bold inline-block"
            :class="[
              experience.tech.stack.includes(tech) ? mapTechToStyle.stack : '',
              experience.tech.dataStructures.includes(tech) ? mapTechToStyle.dataStructures : '',
              experience.tech.designPatterns.includes(tech) ? mapTechToStyle.designPatterns : ''
            ]"
          >
            {{ tech }}
          </small>
        </div>
        
        <p class="text-left md:text-[1em] leading-5 mt-8 px-2" v-html="experience.description"/>
      </div>
    </div>
  </div>
  
</template>


<style>
.tech-animation {
  opacity: 0;
  animation: fadeInTech 1s ease-in-out forwards;
}

.experience-card {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  transition: transform 0.3s, box-shadow 0.3s;
}

@keyframes fadeInTech {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
