export const projects = [
    {
      id: 1,
      project: 'Bloxleveling',
      site: 'https://www.roblox.com',
      role: 'Systems Architect',
      duration: 'July 2023 - Present',
      tech: {
        stack: ["lua"],
        dataStructures: [],
        designPatterns: ["observer", "factory", "strategy"]
      },
      description: 'As a solo developer, I designed and implemented complex systems for an MMORPG game in Roblox, including a dynamic player inventory system, modular combat mechanics, and a reward system. These systems were built with scalability and maintainability in mind, utilizing object-oriented programming principles and design patterns like the Observer and Factory patterns. <br><br> I also developed a player stats system that integrates with badges and inventory items to influence combat outcomes dynamically. Additionally, I refactored server and client architecture to ensure seamless communication and optimize game performance. <br><br> These efforts resulted in a game that is both engaging and modular, allowing for future expansions without impacting existing functionality.'
    },
];
